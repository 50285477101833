import { reset as analyticsReset } from './src/utils/analytics';

$(() => {
  // Logs the logged in user out of Analytics
  $("a[data-logout='true']").on('click', () => {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }

    analyticsReset();
  });
});
