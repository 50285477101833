import $ from 'jquery';

$(document).ready(function() {
  const $container = $('.js-date-range');
  const $start = $container.find('.js-date-range-start');
  const $end = $container.find('.js-date-range-end');

  const setMinDate = () => {
    $end.attr('min', $start.val());
  };

  const setMaxDate = () => {
    $start.attr('max', $end.val());
  };

  const init = () => {
    setMinDate();
    setMaxDate();
  };

  $start.on('change', setMinDate);
  $end.on('change', setMaxDate);
  init();
});
